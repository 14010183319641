/**
 * Creates a debounced function that delays invoking the provided function until after 
 * the specified delay in milliseconds has passed since the last time the debounced 
 * function was invoked.
 * 
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The number of milliseconds to delay.
 * @returns {Function} - The debounced function.
 */
 function debounce(func, delay) {
    let timerId;
    return function(...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  
  export default debounce;