
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isDefined } from '../config/helpers';
import debounce from '@components/config/debounce';

// Hook to get the current window size and mobile status
function useWindowSize() {
  // Get necessary data from the global state
  const template_settings = useSelector(state => state.globalReducer.template_settings);
  const agent = useSelector(state => state.globalReducer.agent);
  const userMobile = agent.isMobile;

  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined });

  // Define a memoized callback function to handle the resize event
  const handleResize = useCallback(debounce(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }, 100), []);


  useEffect(() => {
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  // Define a memoized function to determine the mobile status based on the current window size

  const isMobile = useCallback(() => {
    const { layout } = template_settings;
    return (
      (layout === 'box' && windowSize.width > 1200) ||
      (!isDefined(windowSize.width) && !userMobile) ? false : true
    );
  }, [template_settings, windowSize.width, agent.isMobile]);

  // Return the window size and mobile status
  return {
    ...windowSize,
    isMobile: isMobile(),
  };
}


export default useWindowSize;