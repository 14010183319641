import React, { useMemo } from 'react';
import dynamic from "next/dynamic";
import { useSelector } from 'react-redux';
import ThemeProvider from '@mui/system/ThemeProvider';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useWindowSize from '../_hooks/useWindowSize';
import { isClient, isDefined } from '../config/helpers';
// import InnerHTML from 'dangerously-set-html-content';
import theme from '../Setup/theme';
import useGlobalSettings from '@components/_hooks/useGlobalSettings';

// Dynamic imports
const HeaderLayout = dynamic(() => import("../Components/HeaderComponent/HeaderLayout"));
const Breadcrumb = dynamic(() => import("../Components/HeaderComponent/Breadcrumb"));
const SideNav2 = dynamic(() => import("../Components/Nav/SideNav2"));
const Footer = dynamic(() => import("../Components/Footer/Footer"));
const LayoutMain = dynamic(() => import("./LayoutMain"));
const InitBreadCrumbs = dynamic(() => import("../Components/initComponents/InitBreadCrumbs"));
const BrandStyle = dynamic(() => import("../Setup/styles"));
const AppSchema = dynamic(() => import("../Components/Schemas/AppSchema"));

const BottomNav2 = dynamic(() => import("../Components/BottomNav2/BottomNav2"), {ssr: false});
const ApplicationGlobalPopups = dynamic(() => import("./ApplicationGlobalPopups"), {ssr: false});
const AnalyticsScriptInjector = dynamic(() => import("./InjectAnalytics"), {ssr: false});
const DashbardAppWrapper = dynamic(() => import("../Components/Dashboard/DashbardAppWrapper"), {ssr: false});
const InitData = dynamic(() => import("../Components/initComponents/InitData"), {ssr: false});

const Layout = (props) => {
    const {
        children, breadcrumb, template_page_settings, data, utm_data, _cookie, pagePropsData
    } = props;
    
    const template_settings = useSelector(state => state.globalReducer.template_settings);
    const global_settings = useSelector(state => state.globalReducer.global_settings);
    const breadcrumbs = useSelector(state => state.globalReducer.breadcrumbs);
    const sidenav = useSelector(state => state.globalReducer.sidenav);
    const { isMobile, width } = useWindowSize();
    const {getSetting} = useGlobalSettings();

    const createdTheme = useMemo(() => theme(template_settings), [template_settings]);

    const showSideNav = useMemo(() => {
        return (pagePropsData?.template_page_settings?.sidenav === 'yes' && template_settings.sidenav === 'yes') || (isDefined(sidenav) && sidenav);
    }, [template_settings, pagePropsData, sidenav]);

    return (
        <ThemeProvider theme={createdTheme}>
            <BrandStyle appTheme={createdTheme} />
            <AppSchema breadcrumbProps={breadcrumb} />
            {isClient() ? <AnalyticsScriptInjector data={global_settings.analytics} /> : null}
            {/* <InnerHTML html={global_settings.analytics} /> */}
            
            <HeaderLayout utm_data={utm_data} route={data?.breadcrumb?.last}/>

            <Box>
                {isDefined(breadcrumbs) && breadcrumbs.length !== 0 && <Breadcrumb {...breadcrumb} {...props} />}
                <DashbardAppWrapper />

                <Box sx={{ minHeight: '70vh' }} className={`${showSideNav && !isMobile ? 'boxed-padding' : ''}`}>
                    <Grid container>
                        {showSideNav && !isMobile && <SideNav2 route={data?.breadcrumb?.last} />}
                        <LayoutMain showSideNav={showSideNav}>
                            {children}
                        </LayoutMain>
                    </Grid>
                </Box>
            </Box>

            <Footer />
            {(getSetting('footer_show_mobile_bottom_bar', 'yes') == 'yes') && (isMobile || width < 768) ? <BottomNav2 /> : null}
            {isClient() ? <ApplicationGlobalPopups /> : null }
            {isClient() ? <InitData pagePropsData={pagePropsData} sidenav={template_settings?.sidenav == 'yes'} /> : null}
            <InitBreadCrumbs {...breadcrumb} />
        </ThemeProvider>
    );
};

export default Layout;
