// import { createTheme } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

export default function configureWebsiteTheme(template_settings) {
    const brand_style = {
        components: {
            // Name of the component
            MuiDataGrid: {
                styleOverrides: {
                  // Name of the slot
                  root: {
                    border: 'none',
                    '& .MuiDataGrid-cell--withRenderer': {
                        justifyContent: 'center'
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    "& .MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus": {
                      outline: "none",
                    },
                    '& .MuiDialog-root': {
                        fontSize: {
                            xs: 7,
                            lg: 14
                        }
                    }
        
                  }
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        // '& .MuiSwitch-root': {
                        //     height: '36px',
                        //     '& .MuiButton-root': {
                        //         height: '38px'
                        //     }
                        // }
                    },
                    switchBase: {
                        // height: {
                        //     xs: '38px !important',
                        //     md: '24px !important'
                        // }
                        // height: '38px !important',
                    }
                }
            },
            MuiDatePicker: {
                styleOverrides: {
                    root: {
                        '& .MuiPickerDays-root': {
                            height: 'auto',
                            fontSize: 'initial'
                        }
                    }
                }
            },

            MuiButton: {
                styleOverrides: {
                  // Name of the slot
                  root: {
                    // Some CSS
                    // fontSize: '14px',
                    paddingRight: '18px',
                    paddingBottom: '9px',
                    paddingLeft: '18px',
                    paddingTop: '9px',
                    boxShadow: 'none',
                    transition: '0.2s ease',
                    '&:hover': {
                        transform: 'translateY(-3px)',
                        
                    }
                  },
                },
            },
            MuiLoadingButton: {
                styleOverrides: {
                  // Name of the slot
                  root: {
                    // Some CSS
                    fontSize: '12px',
                    textTransform: "none",
                    paddingRight: '18px',
                    paddingBottom: '9px',
                    paddingLeft: '18px',
                    paddingTop: '9px',
                    boxShadow: 'none',
                    transition: '0.2s ease',
                    '&:hover': {
                        transform: 'translateY(-3px)',
                        
                    }
                  },
                },
            },
        },
        box: {
            'styled-box': {
                transition: '0.2s ease',
                '&:hover': {
                    transform: 'translateY(-3px)',
                    
                }
            }
        },
        typography: {
            p: {
                marginBottom: '10px',
                // marginTop: '10px'
            },
            h1: {
                fontSize: '1.5rem',
                fontWeight: 500,
                marginBottom: '10px',
                // marginTop: '10px',
                lineHeight: 'normal'
              },
            h2: {
                marginBottom: '10px',
                // marginTop: '10px',
                fontSize: '1.5rem',
                fontWeight: 500,
                lineHeight: 'normal'
              },
            h3: {
                fontSize: '1.0rem',
                fontWeight: 500,
                lineHeight: 'normal'
              },
            h4: {
                fontSize: '1.75rem',
                fontWeight: 500,
              },
            h5: {
                fontSize: '1.5rem',
                fontWeight: 400,
            },
              h6: {
                fontSize: '1.25rem',
                fontWeight: 400,
            },
            
            'page-heading': {
                fontSize: {
                    xs: '22px',
                    sm: '26px',
                    md: '30px'
                },
                margin: {
                    xs: '20px 0px 8px 0px',
                    md: '28px 0px 8px 0px',
                }
            },
            allVariants: {
                fontFamily: [ //create backups of font 
                    'Poppins',
                    'sans-serif',
                  ].join(','),
            },
            button: {
                textTransform: "none",
                fontSize: '14px',
                paddingRight: '18px',
                paddingBottom: '9px',
                paddingLeft: '18px',
                paddingTop: '9px',
                padding: 16,
                boxShadow: 'none',
              
            },
            'styled-box': {
                fontSize: parseInt(template_settings.default_font_size),
                transition: '0.2s ease',
                '&:hover': {
                    transform: 'translateY(-3px)',
                }
            },
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
            strong: {
                fontWeight: '700'
            },
            fontSize: parseInt(template_settings.default_font_size),

        },
        palette: {
            primary: {
                main: template_settings.primary_colour,
                contrastText: template_settings.primary_colour_contrast_text,
                dark: template_settings.primary_colour_dark,
                contrastTextDark: template_settings.primary_colour_dark_contrast_text,
            },
            secondary: {
                main: template_settings.secondary_colour,
                contrastText: template_settings.secondary_colour_contrast_text,
                dark: template_settings.secondary_colour_dark,
                contrastTextDark: template_settings.secondary_colour_dark_contrast_text,
            },
            tertiary: {
                main: template_settings.tertiary_colour,
                contrastText: template_settings.tertiary_colour_contrast_text,
                dark: template_settings.tertiary_colour_dark,
                contrastTextDark: template_settings.tertiary_colour_dark_contrast_text,
            },
            success: {
                main: template_settings.success_colour,
                light: '#86ebcb',
                contrastText: template_settings.success_colour_contrast_text,
                dark: template_settings.success_colour_dark,
                contrastTextDark: template_settings.success_colour_dark_contrast_text,
            },

            checkoutAccordion: {
                main: '#ececec',
                contrastText: '#74777a'
            },
            secondaryButton: {
                main: template_settings.secondary_colour,
                dark: template_settings.secondary_colour_dark,
                contrastText: "#fff",
                fontWeight: 800,
                // main: "#d0ffed",
                // dark: '#088f64',
                // contrastText: "#088f64",
                // fontWeight: 800,
            },
            third: {
                main: template_settings.tertiary_colour,
                contrastText: '#fff'
            },
            danger: {
                main: "#d52727",
                contrastText: "#fff",
                fontWeight: 800,
            },
            info: {
                main: "#0288d1",
                light: '#c7edff',
                dark: '#01579b',
                contrastText: "#fff",
                fontWeight: 800,
            },
            advisory: {
                main: '#ffd100',
                contrastText: "#000",
                fontWeight: 800,
            },
            grey: {
                main: "#c6c5c5",
                contrastText: "#fff",
                fontWeight: 800
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff',
            },
            white: {
                main: '#fff',
                contrastText: '#000',
            },
            offWhite: {
                main: '#dde5ef',
                contrastText: '#dde5ef'
            },
            
        },
        white: {

            color: "#fff",
        },
        // fontFamily: template_settings.font_family, //TODO: font family for universal settings

    };

    //themes for selected brands
    const theme = createTheme(brand_style);
    return theme;
}
